<template>
  <div class="auth-wrapper auth-v1">
    <b-row class="auth-inner m-0">
      <!-- Register-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            style="text-align:center; margin-top:-50px;"
          >
            <b-img
              style="width:150px;"
              :src="require(`@/assets/images/partner/${partner}/logo/logo.png`)"
            />
            <br><br><br><br>
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Registrierung
          </b-card-title>
          <!-- form -->
          <b-alert
            v-if="showSuccess"
            :variant="partnerData.colorScheme"
            show
          >
            <h4 class="alert-heading">
              Erfolg
            </h4>
            <div class="alert-body">
              <span
                v-html="successText"
              />
            </div>
          </b-alert>
          <div
            v-if="partnerData.userCanRegisterBonus === true"
          >
            <validation-observer
              v-if="!showSuccess"
              ref="registerForm"
            >
              <b-form class="auth-register-form mt-2">
                <b-form-group
                  label="Email"
                  label-for="register-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="userEmail"
                      name="register-email"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label-for="register-password"
                  :label="authRules.inputLabel"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="authRules.validationTitle"
                    :rules="authRules.inputRule"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-alert
                  v-if="showAlert"
                  variant="danger"
                  show
                >
                  <h4 class="alert-heading">
                    Fehler
                  </h4>
                  <div class="alert-body">
                    <span>{{ alertText }}</span>
                  </div>
                </b-alert>
                <b-button
                  :variant="partnerData.colorScheme"
                  block
                  type="submit"
                  @click.prevent="validationForm"
                >
                  <b-spinner
                    v-if="showLoading"
                    small
                  />
                  jetzt registrieren
                </b-button>
              </b-form>
            </validation-observer>
          </div>

          <p
            v-else
            class="mt-2"
          >
            <br>
            Die Aktion für 2024 ist abgelaufen, Fahrzeugneuanmeldungen für 2024 sind daher leider nicht mehr möglich. Wenn 2025 eine neue Aktion startet, werden Sie über die üblichen Kanäle informiert.
            <br><br>
          </p>

          <p class="text-center mt-2">
            <span>Sie haben bereits ein Konto?</span>
            <b-link
              :to="{name:'login'}"
              :style="getHrefStyle()"
            >
              <span>&nbsp;Anmeldung</span>
            </b-link>
          </p>

          <br>
          <p class="text-center mt-2">
            <span>Probleme mit der Registrierung / Anmeldung?</span>
            <a
              :href="getMailtoText()"
              :style="getHrefStyle()"
            >
              info@ladestellenbonus.at
            </a>
          </p>
        </b-col>
        <div
          style="position: absolute; bottom: 0; right: 0px; font-size:12px; color:#5e587 !important;"
        >
          <b-button
            :to="{name:'impressum'}"
            variant="flat-secondary"
            size="sm"
          >
            Impressum
          </b-button>
        </div>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BLink, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BAlert, BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from '@axios'
import { $authUrl } from '@microServicesConfig'
import router from '@/router'
import store from '@/store/index'

localize('de')
export default {
  components: {
    // VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BAlert,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userEmail: '',
      password: '',
      lastName: '',
      firstName: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      showAlert: false,
      showSuccess: false,
      alertText: '',
      successText: '',
      showLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.showLoading = true
          this.showAlert = false
          this.showSuccess = false

          let authPath = 'register'
          if (window.$cookies.get('partnerData').standardLogin === true) {
            authPath = 'register-standard'
          }

          axios
            .post(`${$authUrl}/auth/${authPath}`, {
              username: this.userEmail, password: this.password, partner: window.$cookies.get('partner'),
            })
            .then(response => {
              if (response.status === 201) {
                this.showSuccess = true
                this.successText = 'Wir haben eine Bestätigungs-E-Mail mit zusätzlichen Informationen an Ihre E-Mail-Adresse gesendet!<br><br>Sollten Sie kein mail erhalten, überprüfen sie ggf. Ihren SPAM-Ordner'
              } if (response.status === 200) {
                this.showSuccess = true
                this.successText = 'Wir haben eine Bestätigungs-E-Mail mit zusätzlichen Informationen an Ihre E-Mail-Adresse gesendet!<br><br>Sollten Sie kein mail erhalten, überprüfen sie ggf. Ihren SPAM-Ordner'
              } else {
                this.showAlert = true

                if (window.$cookies.get('partnerData').standardLogin === true) {
                  this.alertText = 'Die angegebene E-Mail-Adresse existiert bereits.'
                } else {
                  this.alertText = 'Die angegebene Kombination aus E-Mail-Adresse und FIN existiert bereits.'
                }
              }
              this.showLoading = false
            })
            .catch(e => {
              if (e.response.status < 500) {
                if (window.$cookies.get('partnerData').standardLogin === true) {
                  this.alertText = 'Die angegebene E-Mail-Adresse existiert bereits.'
                } else {
                  this.alertText = 'Die angegebene Kombination aus E-Mail-Adresse und FIN existiert bereits.'
                }
              } else {
                this.alertText = 'Fehler beim Verbinden mit dem Server, bitte versuchen Sie es später erneut.'
              }
              this.showAlert = true
              this.showLoading = false
            })
        }
      })
    },
  },
  setup() {
    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`
    document.title = partnerData.value.htmlPageTitle
    document.querySelector("link[rel*='icon']").href = `favicon_${partnerData.value.key}.ico`

    const authRules = ref({
      inputRule: 'required|length:17|alpha-num',
      validationTitle: 'Fahrzeugidentifikationsnummer',
      inputLabel: 'Fahrzeugidentifikationsnummer (FIN, Feld E im Zulassungsschein)',
    })

    if (partnerData.value.standardLogin === true) {
      authRules.value = {
        inputRule: 'required|min:8|alpha-num',
        validationTitle: 'Passwort',
        inputLabel: 'Passwort',
      }

      if (partner.value === 'rvs') {
        authRules.value.inputRule = 'required|min:12|alpha-num'
      }
    }

    const getMailtoText = () => `mailto:info@ladestellenbonus.at?subject=Probleme mit der Registrierung / Anmeldung (${partnerData.value.bonusTitle})`

    const appIdElem = document.getElementById('app')
    let backgroundImage = ''
    if (typeof (window.$cookies.get('partner')) !== 'undefined') {
      backgroundImage = require(`@/assets/images/partner/${partnerData.value.key}/backgorund/auth.jpg`)
      appIdElem.style.backgroundImage = `url('${backgroundImage}')`

      if ((window.$cookies.get('partner') === 'raiffeisen-leasing_genol') || (window.$cookies.get('partner') === 'raiffeisen_genol') || (window.$cookies.get('partner') === 'emobil-link')) {
        appIdElem.style.backgroundPosition = 'top right'
      }
    }

    // fallback
    if (partnerData.value === null) {
      router.push('/partner/ladestellenbonus/register')
    }

    return {
      partner,
      partnerData,
      getHrefStyle,
      authRules,
      getMailtoText,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
