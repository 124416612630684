<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-row class="auth-inner m-0">

      <!-- Login-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            style="text-align:center;"
            :style="{ marginTop: (partner === 'genol') ? '10px' : '-50px'}"
          >
            <b-img
              style="width:150px;"
              :src="require(`@/assets/images/partner/${partner}/logo/logo.png`)"
            />
            <br><br><br><br>
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Login
          </b-card-title>
          <b-card-text class="mb-2">
            Bitte melden Sie sich in Ihrem Konto an
          </b-card-text>
          <!--
          <p
            v-if="partnerData.userCanRegisterBonus !== true"
            class="mt-2"
          >
            Die Aktion für 2024 ist abgelaufen, Fahrzeugneuanmeldungen für 2024 sind daher leider nicht mehr möglich. Wenn 2025 eine neue Aktion startet, werden Sie über die üblichen Kanäle informiert.
            <br><br>
          </p>
          -->
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{  authRules.inputLabel }}</label>
                  <!--
                  <b-link :to="{name:'otp-request'}">
                    <span>&nbsp;OTP zusenden</span>
                  </b-link>
                  -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="authRules.validationTitle"
                  :rules="authRules.inputRule"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-alert
                v-if="showAlert"
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  Fehler
                </h4>
                <div class="alert-body">
                  <span>{{ alertText }}</span>
                </div>
              </b-alert>

              <!-- submit buttons -->
              <b-button
                type="submit"
                :variant="partnerData.colorScheme"
                block
                @click="validationForm"
              >
                <!--Bonus beantragen-->
                LOGIN
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-left mt-2">
            <span>Noch nicht registriert?</span>
            <b-link :to="{name:'register'}">
              <span :style="getHrefStyle()">&nbsp;Registrierung</span>
            </b-link>
            <br>
            <span>{{ authRules.otpLabel }}</span>
            <b-link :to="{name:'otp-request'}">
              <span :style="getHrefStyle()">&nbsp;Einmalpasswort zusenden</span>
            </b-link>
          </b-card-text>

          <br>
          <p class="text-center mt-2">
            <span>Probleme mit der Registrierung / Anmeldung?</span>
            <a
              :href="getMailtoText()"
              :style="getHrefStyle()"
            >
              info@ladestellenbonus.at
            </a>
          </p>
        </b-col>
        <div
          style="position: absolute; bottom: 0; right: 0px; font-size:12px; color:#5e587 !important;"
        >
          <b-button
            :to="{name:'impressum'}"
            variant="flat-secondary"
            size="sm"
          >
            Impressum
          </b-button>
        </div>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BAlert,
  BImg,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import jwtDecode from 'jwt-decode'
import axios from '@axios'
import router from '@/router'
import store from '@/store/index'
import homeStoreModule from '../homeStoreModule'
//  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

localize('de')
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    let optData = []
    optData[0] = ''
    optData[1] = ''
    if (typeof (this.$router.currentRoute.params.token) !== 'undefined') {
      const decodedString = Buffer.from(this.$router.currentRoute.params.token, 'base64').toString()
      optData = decodedString.split('|')
    }

    return {
      status: '',
      password: optData[1],
      userEmail: optData[0],
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      showAlert: false,
      alertText: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    getUserAbility() {
      const managerAbility = [
        {
          action: 'manage',
          subject: 'Users',
        },
        {
          action: 'manage',
          subject: 'Dashboard',
        },
        {
          action: 'manage',
          subject: 'Auth',
        },
      ]

      return managerAbility
    },
    getMangerAbility() {
      const managerAbility = [
        {
          action: 'manage',
          subject: 'Dashboard',
        },
        {
          action: 'manage',
          subject: 'Users',
        },
        {
          action: 'manage',
          subject: 'SolarPlant',
        },
        {
          action: 'manage',
          subject: 'Auth',
        },
      ]

      return managerAbility
    },
    getAdminAbility() {
      const adminAbility = [
        {
          action: 'manage',
          subject: 'all',
        },
      ]

      return adminAbility
    },
    validationForm() {
      //  This is temp only, do logic first!
      //  this.$router.push('/')
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.showAlert = false

          if (!window.$cookies.get('partner') && window.$cookies.get('partner').length === 0) {
            return false
          }

          useJwt.login({
            username: this.userEmail,
            password: this.password,
            partner: window.$cookies.get('partner'),
          })
            .then(response => {
              if (response.status === 200) {
                useJwt.setToken(response.data.accessToken)
                useJwt.setRefreshToken(response.data.refreshToken)

                const token = response.data.accessToken
                const decodedToken = jwtDecode(token)

                let userLevel = 'user'

                let userAbility = []
                if (decodedToken.realm_access.roles.includes('user')) {
                  userAbility = this.getUserAbility()
                }

                if (decodedToken.realm_access.roles.includes('manager')) {
                  userAbility = this.getMangerAbility()
                  userLevel = 'manager'
                }

                if (decodedToken.realm_access.roles.includes('admin')) {
                  userAbility = this.getAdminAbility()
                  userLevel = 'admin'
                }

                let newCarVin = ''
                if (!window.$cookies.get('partnerData').standardLogin) {
                  newCarVin = this.password
                }

                const userData = {
                  //  id: decodedToken.sub,
                  //  uid: decodedToken.sid,
                  id: response.data.userId,
                  cid: response.data.carId,
                  fullName: `${response.data.firstName} ${response.data.lastName}`,
                  username: decodedToken.preferred_username,
                  avatar: '',
                  email: decodedToken.email,
                  ability: userAbility,
                  firstName: response.data.firstName,
                  lastName: response.data.lastName,
                  partner: response.data.partner,
                  userLevel,
                  newCarVin,
                }

                //  window.$cookies.set('userData', userData, '1D')
                window.$cookies.set('userData', userData, '1D', null, null, true, 'None')
                this.$ability.update(userData.ability)

                window.sessionStorage.setItem('activeTime', Date.now())

                let partner = 'ladestellenbonus'
                if (response.data.partner !== '') {
                  partner = response.data.partner
                }

                /*
                this.axios
                  .get(`/partner/get/${partner}`)
                  .then(response1 => console.log(response1))
                  .catch(error => console.log(error))
                */
                this.getPartnerData(partner)
              }
            })
            .catch(error => {
              this.showAlert = true
              if (window.$cookies.get('partnerData').standardLogin === true) {
                this.alertText = 'Die angegebene Kombination aus E-Mail-Adresse und Passwort ist ungültig.'
              } else {
                this.alertText = 'Die angegebene Kombination aus E-Mail-Adresse und FIN ist ungültig.'
              }
            })
        }
      })
    },
  },
  setup() {
    const APP_HOME_STORE_MODULE_NAME = 'app-home'

    // Register module
    if (!store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.registerModule(APP_HOME_STORE_MODULE_NAME, homeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      //  if (store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.unregisterModule(APP_HOME_STORE_MODULE_NAME)
    })

    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`
    document.title = partnerData.value.htmlPageTitle
    document.querySelector("link[rel*='icon']").href = `favicon_${partnerData.value.key}.ico`

    const appIdElem = document.getElementById('app')
    let backgroundImage = ''
    if (typeof (window.$cookies.get('partner')) !== 'undefined') {
      backgroundImage = require(`@/assets/images/partner/${partner.value}/backgorund/auth.jpg`)
      appIdElem.style.backgroundImage = `url('${backgroundImage}')`

      if ((window.$cookies.get('partner') === 'raiffeisen-leasing_genol') || (window.$cookies.get('partner') === 'raiffeisen_genol') || (window.$cookies.get('partner') === 'emobil-link')) {
        appIdElem.style.backgroundPosition = 'top right'
      }
    }
    const getPartnerData = partnerKey => {
      axios
        .get(`/partner/get/${partnerKey}`)
        .then(response => {
          if (response.status === 200) {
            //  window.$cookies.set('partnerData', response.data, Infinity)
            window.$cookies.set('partnerData', response.data, Infinity, null, null, true, 'None')
            document.title = response.data.htmlPageTitle
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          //  const appIdElem = document.getElementById('app')
          appIdElem.style.backgroundImage = 'none'
          router.go('/')
        })
      /*
      store
        .dispatch(`${APP_HOME_STORE_MODULE_NAME}/getPartnerData`, { partnerKey })
        .then(responsePartner => {
          if (responsePartner.status === 200) {
            window.$cookies.set('partnerData', responsePartner.data, Infinity)
            document.title = responsePartner.data.htmlPageTitle
          }
        })
        .finally(() => {
          //  const appIdElem = document.getElementById('app')
          appIdElem.style.backgroundImage = 'none'
          this.$router.go('/')
        })
        */
    }

    // fallback
    if (partnerData.value === null) {
      router.push('/partner/ladestellenbonus/login')
    }

    const authRules = ref({
      inputRule: 'required|length:17|alpha-num',
      validationTitle: 'Fahrzeugidentifikationsnummer',
      inputLabel: 'Fahrzeugidentifikationsnummer (FIN, Feld E im Zulassungsschein)',
      otpLabel: 'FIN vergessen?',
    })

    if (partnerData.value.standardLogin === true) {
      authRules.value = {
        inputRule: 'required|min:8|alpha-num',
        validationTitle: 'Passwort',
        inputLabel: 'Passwort',
        otpLabel: 'Passwort vergessen?',
      }
      if (partner.value === 'rvs') {
        authRules.value.inputRule = 'required|min:12|alpha-num'
      }
    }

    const getMailtoText = () => `mailto:info@ladestellenbonus.at?subject=Probleme mit der Registrierung / Anmeldung (${partnerData.value.bonusTitle})`

    return {
      partner,
      partnerData,
      getHrefStyle,
      getPartnerData,
      authRules,
      getMailtoText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
